<template>
  <div class="content">
    <div class="top-content">
      <div class="top-inner">
        <div class="header-logo">
          <router-link to="/home" class="logo" title="蓝矩科技">蓝矩科技</router-link>
        </div>
        <div class="nav">
          <ul style="display: flex">
            <li class="nav-item" v-for="(item,index) in navItems" :key="index">
              <router-link class="item-a" :class="{'active-line':nav.parent==index}" :to="item.link"
                           v-if="!item.children">
                {{ item.title }}
              </router-link>
              <div v-else class="drop-down item-a"
                   :class="{'active-line':nav.parent==index}">
                {{ item.title }}
                <el-icon class="el-icon--right icon">
                  <arrow-down/>
                </el-icon>
                <div class="drop-down-list">
                  <div class="list-box">
                    <router-link class="drop-a" :class="{'active':nav.parent==index && nav.child==index1}"
                                 :to="item1.link"
                                 v-for="(item1,index1) in item.children" :key="index1">
                      <div class="title">{{ item1.title }}</div>
                      <div class="intro" v-html="item1.intro"></div>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {useRouter} from 'vue-router'

const props = defineProps({
  items: {
    type: Array,
    default: () => [
      {
        title: '蓝矩产品',
        children: [
          {
            title: '校友会系统',
            intro: '·服务校友·服务学校·服务社会',
            link: '/alumni'
          },
          {
            title: '基金会系统',
            intro: '·筹措资金·管理项目·服务捐赠人',
            link: '/foundation'
          },
          {
            title: '云捐赠系统',
            intro: '·校友·在校师生·社会人士',
            link: '/donation'
          },
          {
            title: '云活动系统',
            intro: '·校友·在校师生·社会人士',
            link: '/CloudActivity'
          },
          // {
          //   title: '数据统一服务平台',
          //   intro: '·国内首家校友，基金数据平台',
          //   link: '/DataService'
          // },
          // {
          //   title: '重点资源管理系统',
          //   intro: '√人物 √企业 √人物动态 √企业动态',
          //   link: '/ResourceManage'
          // },
          {
            title: '网站群系统',
            intro: '·智慧融合，一键掌控',
            link: '/WebsiteGroup'
          },
          {
            title: '蓝矩大数据治理系统',
            intro: '·专注行业信息，满足行业需求',
            link: '/BigdataAcquisition'
          },
          {
            title: '蓝矩大模型分析系统',
            intro: '·紧跟时代脚步，探索最新应用',
            link: '/LLMSystem'
          },
          {
            title: '蓝矩大数据服务系统',
            intro: '·紧跟时代脚步，促进行业发展',
            link: '/BigDataService'
          },
        ]
      },
      {
        title: '服务支持',
        children: [
          {
            title: '项目实施服务',
            intro: '从项目启动到系统运维，全流程服务贯通',
            link: '/ProjectImplement'
          },
          {
            title: '数据支持服务',
            intro: '连接校友，校友会、基金会数据好帮手',
            link: '/DataSupport'
          },
          {
            title: '运营支持服务',
            intro: '校友全方位运营支持，让您体验安心',
            link: '/OperationalSupport'
          },
          {
            title: '售后服务',
            intro: '放心的服务质量和你全程相伴',
            link: '/AfterSales'
          },
        ]
      },
      {
        title: '解决方案',
        children: [
          {
            title: '蓝矩校友会解决方案',
            intro: '致力于帮助校友会更好地服务校友、<br>服务学院和服务社会。',
            link: '/AlumniSolution'
          },
          {
            title: '蓝矩基金会解决方案',
            intro: '致力于帮助基金会更好地筹措资金、<br>管理项目和服务捐赠人。',
            link: '/FoundationSolution'
          },
          {
            title: '蓝矩数据解决方案',
            intro: '基于蓝矩数据平台，提供专业数据服务，<br>赋能行业发展。',
            link: '/DataSolution'
          },
          {
            title: '蓝矩运营解决方案',
            intro: '基于蓝矩最佳实践，提供专业运营服务，<br>赋能行业发展',
            link: '/OperationalSolution'
          },
        ]
      },
      {
        title: '典型案例',
        link: '/case'
      },
      {
        title: '蓝矩资讯',
        link: '/news'
      },
      {
        title: '关于蓝矩',
        link: '/about'
      },
      {
        title:'加入我们',
        link:'/joinus'
      }
    ]
  }
})

const updateUrl = (newValue) => {
  window.scrollTo({top: 0})
  Object.values(navItems.value).forEach((val, index) => {
    if (val.children) {
      val.children.forEach((val1, index1) => {
        if (newValue == val1.link) {
          nav.value.parent = index
          nav.value.child = index1
        }
      })
    } else if (newValue == val.link) {
      nav.value.parent = index
    }
  })
}

// 父组件传的值
const navItems = ref(props.items)
// 高亮的栏目
const nav = ref({
  parent: -1,
  child: -1
})
const router = useRouter()

// 监听当前路由的name变化
watch(
    () => router.currentRoute.value,
    (newValue) => {
      if (newValue.path == '/home') {
        nav.value.parent = -1
      }
      updateUrl(newValue.path)
    },
    {immediate: true}
)

</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.content {
  position: relative;
}

.top-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, .5);
}

.top-inner {
  width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-size: 23px;
  line-height: 80px;
}

.header-logo {
  height: 39px;
}

.logo {
  display: block;
  width: 180px;
  height: 39px;
  background: url(../../assets/icons/logo_lg.png) no-repeat;
  background-size: contain;
  text-indent: -9999em;
}

.active {
  color: #2165FB !important;
}

.active-line {
  color: #2165FB !important;
  border-bottom: 4px solid #2165FB;
}

.nav {
  display: flex;

  .nav-item {
    position: relative;
    margin-left: 60px;
    cursor: pointer;

    .drop-down {
      position: relative;
      font-family: SourceHanSansCN-Medium;
      transition: color 0.2s ease;

      .icon {
        transition: all .3s;
      }

      &:hover {
        color: #2165FB;

        .icon {
          transform: translateY(2px);
        }

        .drop-down-list {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }

      .drop-down-list {
        transform: translateY(-4px);
        transition: opacity .25s, visibility .25s, transform .25s;
        transition-behavior: normal, normal, normal;
        transition-duration: 0.25s, 0.25s, 0.25s;
        transition-timing-function: ease, ease, ease;
        transition-delay: 0s, 0s, 0s;
        transition-property: opacity, visibility, transform;
        opacity: 0;
        visibility: hidden;

        overflow: hidden;
        position: absolute;
        margin-top: -10px;
        left: 50%;
        margin-left: -170.5px;
        width: 341px;
        box-sizing: border-box;
        padding-left: 27px;
        z-index: 10;
        box-shadow: 0px 10px 20px 2px rgba(105, 104, 104, 0.1);
        border-radius: 20px;
        background: url(@/assets/banner/dropdown-bg.png);
        background-size: cover;

        .list-box {
          padding: 10px 0;

          .drop-a {
            display: block;
            padding: 17px 0;
            position: relative;
            color: #3D3D3D;
            transition: color 0.2s ease;

            .title {
              font-size: 23px;
              line-height: 23px;
              font-family: SourceHanSansCN-Medium;
              font-weight: 400;
              position: relative;

              &:before {
                content: '';
                display: inline-block;
                margin-right: 5px;
                width: 5px;
                height: 22px;
                background: #2165FB;
                vertical-align: bottom;
              }
            }

            .intro {
              line-height: 20px;
              margin-top: 8px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular;
            }
          }
        }
      }
    }

    .item-a {
      display: block;
      height: 80px;
      color: #333333;
      transition: color 0.2s ease;
      font-family: SourceHanSansCN-Medium;
      box-sizing: border-box;
    }

    a:hover {
      color: #2165FB !important;
    }
  }
}
</style>
